// extracted by mini-css-extract-plugin
export const heroRoot = "singleBlog-module--heroRoot--2J1O4";
export const heroImg = "singleBlog-module--heroImg--3zKNn";
export const sidebarTextBodyContainer = "singleBlog-module--sidebarTextBodyContainer--3lqXR";
export const adRoot = "singleBlog-module--adRoot--3qhPD";
export const textBodyRoot = "singleBlog-module--textBodyRoot--1G7Yg";
export const date = "singleBlog-module--date--HUbHe";
export const htmlText = "singleBlog-module--htmlText--23dpR";
export const sidebarRoot = "singleBlog-module--sidebarRoot--3ODxe";
export const item = "singleBlog-module--item--1EJrr";
export const tagDate = "singleBlog-module--tagDate--2uBza";
export const prevNextRoot = "singleBlog-module--prevNextRoot--2_QLq";
export const linkCard = "singleBlog-module--linkCard--2MALm";