import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as style from "../../static/styles/singleBlog.module.scss"

const sidebar = ({ posts }) => {
    return (
        <div className={style.sidebarRoot}>
            {posts.map((x, index) => {
                const { publishDate, slug, title, heroImage, description, tags } = x.node
                return(
                    <Link to={`/blog/${slug}`} key={index} className={style.item}>
                        <GatsbyImage image={heroImage.gatsbyImageData} alt="Hero"/>
                        <h3>{25 < title.length ? `${title.slice(0, 25)}...`: title}</h3>
                        <p className={style.tagDate}><span>{tags[0]}</span> {publishDate}</p>
                        <div dangerouslySetInnerHTML={{__html: description.childMarkdownRemark.html}}/> 
                    </Link>
                )
            })}
        </div>
    )
}

export default sidebar