import React from 'react'
import * as style from "../../static/styles/singleBlog.module.scss"

const  TextBody = ({ title, publishDate, html}) => {
    return (
        <div className={style.textBodyRoot}>
            <h1>{title}</h1>
            <hr/>
            <p className={style.date}>{publishDate}</p>
            <div dangerouslySetInnerHTML={{__html: html}} className={style.htmlText}/> 
        </div>
    )
}

export default TextBody