import React from 'react'
import { graphql/* , Link  */} from "gatsby"
import * as style from "../static/styles/singleBlog.module.scss"
import Seo from "../components/seo"
import Hero from "../components/singleBlog/hero"
//import Ad from "../components/singleBlog/ad"
import Sidebar from "../components/singleBlog/sidebar"
import TextBody from "../components/singleBlog/textBody"
import PrevNext from "../components/singleBlog/prevNext"
import Footer from "../components/footer"

const SingleBlog = ({ data, pageContext }) => {
    const { body, heroImage, publishDate, title } = data.contentfulBlogPost
    return (
        <div>
            <Seo title={title} description={body.body}/>
            <Hero heroImage={heroImage} />
            <div className={style.sidebarTextBodyContainer}>
                <Sidebar posts={data.allContentfulBlogPost.edges}/>
                <TextBody title={title} publishDate={publishDate} html={body.childMarkdownRemark.html}/>
                <PrevNext pageContext={pageContext}/>
            </div>
            <Footer/>
        </div>
    )
}

export default SingleBlog

export const query = graphql`
  query SingleBlogQuery($slug: String!) {
            contentfulBlogPost(slug: { eq: $slug }) {
              title
              publishDate(formatString: "MMMM Do, YYYY")
              heroImage {
                gatsbyImageData(placeholder: BLURRED)
              }
              body {
                childMarkdownRemark {
                  html
                }
              }
            }
            allContentfulBlogPost(limit: 6) {
              edges {
                node {
                  title
                  slug
                  publishDate(formatString: "MMMM Do, YYYY")
                  tags
                  heroImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                        width: 350
                        height: 196
                        resizingBehavior: SCALE
                        layout: FULL_WIDTH
                    )
                  }
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
  }
`