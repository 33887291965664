import React from 'react'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as style from "../../static/styles/singleBlog.module.scss"

const Hero = ({ heroImage }) => {
    return (
        <div className={style.heroRoot}>   
            <h1><Link to="/">Gamersmancave NL</Link></h1>
            <GatsbyImage image={heroImage.gatsbyImageData} alt="Hero" className={style.heroImg}/>
        </div>
    )
}

export default Hero