import * as React from "react"
import { Link } from 'gatsby'
import ArrowLeft from "../../static/images/svgImportAsComponent/arrow-left.svg"
import ArrowRight from "../../static/images/svgImportAsComponent/arrow-right.svg"
import * as style from "../../static/styles/singleBlog.module.scss"

const PrevNext =({ pageContext }) => {
    const { previous, next } = pageContext
    return (
        <div className={style.prevNextRoot}>
            {previous && 
                <Link to={`/blog/${previous.slug}`} className={style.linkCard}>
                    <ArrowLeft/>
                    <h3> {25 < previous.title.length ? `${previous.title.slice(0, 30)}...`: previous.title}</h3>
                </Link>
            }
            {next && 
                <Link to={`/blog/${next.slug}`} className={style.linkCard}>
                    <h3>{25 < next.title.length ? `${next.title.slice(0, 30)}...`: next.title}</h3>
                    <ArrowRight/>
                </Link>
            }
        </div>
    )
}

export default PrevNext